import React, { useState } from 'react'
import Box from '../../../../../remitbee/components/box/Box';
import classes from './IBANCalculator.module.scss'
import Typography from '../../../../../remitbee/components/typography/Typography';
import theme from '../../../../../remitbee/theme/Theme';
import DropDown from '../../../../../remitbee/components/dropDown/DropDown';
import DropDownItem from '../../../../../remitbee/components/dropDown/DropDownItem';
import Flag from '../../../../../remitbee/components/flag/Flag';
import Table from '../FullWidthSection/Table';
import { useMediaQuery } from '@material-ui/core';

export const ibanData = [
    {
        name: "Belgium",
        iso2: "BE",
        iso3: "BEL",
        phone: "+32",
        iban: "BE68539007547034",
        sepa: true,
        fields: [
            { field_name: "bank_identifier", regex: "^[0-9]{3}$", label: "Bank code" },
            { field_name: "account_identifier", regex: "^[0-9]{7}$", label: "Bank account number" },
            { field_name: "national_code", regex: "^[0-9]{2}$", label: "National check digit" },
        ],
        check_digit: 68
    },
    {
        name: "Switzerland",
        iso2: "CH",
        iso3: "CHE",
        phone: "+41",
        iban: "CH9300762011623852957",
        sepa: true,
        fields: [
            { field_name: "bank_identifier", regex: "^[0-9]{5}$", label: "Bank code" },
            { field_name: "account_identifier", regex: "^[0-9]{12}$", label: "Bank account number" },
        ],
        check_digit: 93
    },
    {
        name: "Germany",
        iso2: "DE",
        iso3: "DEU",
        phone: "+49",
        iban: "DE89370400440532013000",
        sepa: true,
        fields: [
            { field_name: "bank_identifier", regex: "^[0-9]{8}$", label: "Bank code" },
            { field_name: "account_identifier", regex: "^[0-9]{10}$", label: "Bank account number" },
        ],
        check_digit: 89
    },
    {
        name: "Estonia",
        iso2: "EE",
        iso3: "EST",
        phone: "+372",
        iban: "EE382200221020145685",
        sepa: true,
        fields: [
            { field_name: "bank_identifier", regex: "^[0-9]{2}$", label: "Bank code" },
            { field_name: "branch_identifier", regex: "^[0-9]{2}$", label: "Branch code" },
            { field_name: "account_identifier", regex: "^[0-9]{11}$", label: "Bank account number" },
            { field_name: "national_code", regex: "^[0-9]{1}$", label: "National check digit" },
        ],
        check_digit: 38
    },
    {
        name: "Spain",
        iso2: "ES",
        iso3: "ESP",
        phone: "+34",
        iban: "ES9121000418450200051332",
        sepa: true,
        fields: [
            { field_name: "bank_identifier", regex: "^[0-9]{4}$", label: "Bank code" },
            { field_name: "branch_identifier", regex: "^[0-9]{4}$", label: "Branch code" },
            { field_name: "national_code", regex: "^[0-9]{2}$", label: "National check digit" },
            { field_name: "account_identifier", regex: "^[0-9]{10}$", label: "Bank account number" },
        ],
        check_digit: 91
    },
    {
        name: "Finland",
        iso2: "FI",
        iso3: "FIN",
        phone: "+358",
        iban: "FI2112345600000785",
        sepa: true,
        fields: [
            { field_name: "bank_identifier", regex: "^[0-9]{6}$", label: "Bank code" },
            { field_name: "account_identifier", regex: "^[0-9]{7}$", label: "Bank account number" },
            { field_name: "national_code", regex: "^[0-9]{1}$", label: "National check digit" },
        ],
        check_digit: 21
    },
    {
        name: "France",
        iso2: "FR",
        iso3: "FRA",
        phone: "+33",
        iban: "FR1420041010050500013M02606",
        sepa: true,
        fields: [
            { field_name: "bank_identifier", regex: "^[0-9]{5}$", label: "Bank code" },
            { field_name: "branch_identifier", regex: "^[0-9]{5}$", label: "Branch code" },
            { field_name: "account_identifier", regex: "^[0-9]{11}$", label: "Bank account number" },
            { field_name: "national_code", regex: "^[0-9]{2}$", label: "National check digit" },
        ],
        check_digit: 14
    },
    {
        name: "United Kingdom",
        iso2: "GB",
        iso3: "GBR",
        phone: "+44",
        iban: "GB29NWBK60161331926819",
        sepa: true,
        fields: [
            { field_name: "bank_identifier", regex: "^[0-9]{4}$", label: "Bank code" },
            { field_name: "branch_identifier", regex: "^[0-9]{6}$", label: "Branch code" },
            { field_name: "account_identifier", regex: "^[0-9]{8}$", label: "Bank account number" },
        ],
        check_digit: 29
    },
    {
        name: "Hungary",
        iso2: "HU",
        iso3: "HUN",
        phone: "+36",
        iban: "HU42117730161111101800000000",
        sepa: true,
        fields: [
            { field_name: "bank_identifier", regex: "^[0-9]{3}$", label: "Bank code" },
            { field_name: "branch_identifier", regex: "^[0-9]{4}$", label: "Branch code" },
            { field_name: "account_identifier", regex: "^[0-9]{16}$", label: "Bank account number" },
            { field_name: "national_code", regex: "^[0-9]{1}$", label: "National check digit" },
        ],
        check_digit: 42
    },
    {
        name: "Ireland",
        iso2: "IE",
        iso3: "IRL",
        phone: "+353",
        iban: "IE29AIBK93115212345678",
        sepa: true,
        fields: [
            { field_name: "bank_identifier", regex: "^[0-9]{4}$", label: "Bank code" },
            { field_name: "branch_identifier", regex: "^[0-9]{6}$", label: "Branch code" },
            { field_name: "account_identifier", regex: "^[0-9]{8}$", label: "Bank account number" },
        ],
        check_digit: 29
    },
    {
        name: "Italy",
        iso2: "IT",
        iso3: "ITA",
        phone: "+39",
        iban: "IT60X0542811101000000123456",
        sepa: true,
        fields: [
            { field_name: "bank_identifier", regex: "^[0-9]{5}$", label: "Bank code" },
            { field_name: "branch_identifier", regex: "^[0-9]{5}$", label: "Branch code" },
            { field_name: "account_identifier", regex: "^[0-9]{12}$", label: "Bank account number" },
            { field_name: "national_code", regex: "^[0-9]{1}$", label: "National check digit" },
        ],
        check_digit: 60
    },
    {
        name: "Monaco",
        iso2: "MC",
        iso3: "MCO",
        phone: "+377",
        iban: "MC5810096180790123456789085",
        sepa: true,
        fields: [
            { field_name: "bank_identifier", regex: "^[0-9]{5}$", label: "Bank code" },
            { field_name: "branch_identifier", regex: "^[0-9]{5}$", label: "Branch code" },
            { field_name: "account_identifier", regex: "^[0-9]{11}$", label: "Bank account number" },
            { field_name: "national_code", regex: "^[0-9]{2}$", label: "National check digit" },
        ],
        check_digit: 58
    },
    {
        name: "Malta",
        iso2: "MT",
        iso3: "MLT",
        phone: "+356",
        iban: "MT84MALT011000012345MTLCAST001S",
        sepa: true,
        fields: [
            { field_name: "bank_identifier", regex: "^[0-9]{4}$", label: "Bank code" },
            { field_name: "branch_identifier", regex: "^[0-9]{5}$", label: "Branch code" },
            { field_name: "account_identifier", regex: "^[0-9]{18}$", label: "Bank account number" },
        ],
        check_digit: 84
    },
    {
        name: "Netherlands",
        iso2: "NL",
        iso3: "NLD",
        phone: "+31",
        iban: "NL91ABNA0417164300",
        sepa: true,
        fields: [
            { field_name: "bank_identifier", regex: "^[A-Z]{4}$", label: "Bank code" },
            { field_name: "account_identifier", regex: "^[0-9]{10}$", label: "Bank account number" },
        ],
        check_digit: 91
    },
    {
        name: "Norway",
        iso2: "NO",
        iso3: "NOR",
        phone: "+47",
        iban: "NO9386011117947",
        sepa: true,
        fields: [
            { field_name: "bank_identifier", regex: "^[0-9]{4}$", label: "Bank code" },
            { field_name: "account_identifier", regex: "^[0-9]{6}$", label: "Bank account number" },
            { field_name: "national_code", regex: "^[0-9]{1}$", label: "National check digit" },
        ],
        check_digit: 93
    },
    {
        name: "Austria",
        iso2: "AT",
        iso3: "AUT",
        phone: "+43",
        iban: "AT611904300234573201",
        sepa: true,
        fields: [
            { field_name: "bank_identifier", regex: "^[0-9]{5}$", label: "Bank code" },
            { field_name: "account_identifier", regex: "^[0-9]{11}$", label: "Bank account number" },
        ],
        check_digit: 61
    },
    {
        name: "Poland",
        iso2: "PL",
        iso3: "POL",
        phone: "+48",
        iban: "PL61109010140000071219812874",
        sepa: true,
        fields: [
            { field_name: "bank_identifier", regex: "^[0-9]{3}$", label: "Bank code" },
            { field_name: "branch_identifier", regex: "^[0-9]{4}$", label: "Branch code" },
            { field_name: "account_identifier", regex: "^[0-9]{16}$", label: "Bank account number" },
            { field_name: "national_code", regex: "^[0-9]{1}$", label: "National check digit" },
        ],
        check_digit: 61
    },
    {
        name: "Portugal",
        iso2: "PT",
        iso3: "PRT",
        phone: "+351",
        iban: "PT50000201231234567890154",
        sepa: true,
        fields: [
            { field_name: "bank_identifier", regex: "^[0-9]{4}$", label: "Bank code" },
            { field_name: "branch_identifier", regex: "^[0-9]{4}$", label: "Branch code" },
            { field_name: "account_identifier", regex: "^[0-9]{11}$", label: "Bank account number" },
            { field_name: "national_code", regex: "^[0-9]{2}$", label: "National check digit" },
        ],
        check_digit: 50
    },
    {
        name: "Slovakia",
        iso2: "SK",
        iso3: "SVK",
        phone: "+421",
        iban: "SK3112000000198742637541",
        sepa: true,
        fields: [
            { field_name: "bank_identifier", regex: "^[0-9]{4}$", label: "Bank code" },
            { field_name: "account_identifier", regex: "^[0-9]{16}$", label: "Bank account number" },
        ],
        check_digit: 31
    },
    {
        name: "Slovenia",
        iso2: "SI",
        iso3: "SVN",
        phone: "+386",
        iban: "SI56191000000123438",
        sepa: true,
        fields: [
            { field_name: "bank_identifier", regex: "^[0-9]{5}$", label: "Bank code" },
            { field_name: "account_identifier", regex: "^[0-9]{8}$", label: "Bank account number" },
            { field_name: "national_code", regex: "^[0-9]{2}$", label: "National check digit" },
        ],
        check_digit: 56
    }
];


const IBANExamples = () => {
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [filteredCountryList, setFilteredCountryList] = useState(ibanData);
    const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.md}px)`)

    const handleCountryChange = (e) => {
        const itemData = JSON.parse(e.currentTarget.getAttribute('data-item'));
        if (!itemData) return
        setSelectedCountry(itemData);
    }

    const handleCountrySearch = (typed: string, receiveSuggestions: any) => {
        if (typed && typed !== '') {
            const filteredList = ibanData.filter(c => c.name.toUpperCase().includes(typed.toUpperCase()));
            filteredList.length > 0 ? setFilteredCountryList(filteredList) : setFilteredCountryList([]);
        } else setFilteredCountryList(ibanData);
    }

    const renderCountry = (country:any, index?:number) => {
        return (
            <DropDownItem
                key={index}
                value={country?.name}
                data={country}
                onClick={handleCountryChange}
            >
                <Box display='flex' alignItems='center'>
                    <Flag code={country?.iso2} />
                    <Box ml={3}>{country?.name}</Box>
                </Box>
            </DropDownItem>
        )
    }

    const columnsData = [{
        col1: <Typography variant='textXl' weight='semibold' color={theme.palette.primary.navy}> Country name</Typography>,
        col2: <Typography variant='textXl' align='center' weight='semibold' color={theme.palette.primary.navy}> SEPA</Typography>,
        col3: <Typography variant='textXl' align='center' weight='semibold' color={theme.palette.primary.navy}> Length</Typography>,
        col4: <Typography variant='textXl' weight='semibold' color={theme.palette.primary.navy}> IBAN Examples</Typography>
    }];

    const finalData = selectedCountry ? [selectedCountry] : filteredCountryList.slice(0,10)
    const rowData = finalData.map((country) => {
        return {
            col1: <Box gap={8} display='flex' alignItems='center'><Flag code={country?.iso2} /><Typography variant='textMd' weight='semibold' color={theme.palette.primary.navy}>{country?.name}</Typography></Box>,
            col2: <Typography variant='textMd' align='center' weight='medium'>{country?.sepa ? 'Yes' : 'No'}</Typography>,
            col3: <Typography variant='textMd' align='center' weight='medium'>{country?.iban.length}</Typography>,
            col4: <Typography variant='textMd' weight='medium'>{country?.iban}</Typography>
        }
    });

    const mobileColumnsData = [{
        col1: <Typography variant='textLg' weight='bold' color={theme.palette.primary.navy}> Country name</Typography>
    }];

    const mobileRowData = finalData.map((country) => {
        return {
            col1: <Box display='flex' flexDirection='column' gap={8}>
                <Box gap={8} display='flex' alignItems='center'><Flag code={country?.iso2} /><Typography variant='textMd' weight='semibold' color={theme.palette.primary.navy}>{country?.name}</Typography></Box>
                <Typography variant='textMd' weight='medium'>{country?.iban}</Typography>
            </Box>
        }
    })

  return (
    <section>
       <Box
          flexDirection={'column'}
          className={classes['rb-root']}
       >
        <Box display='flex' flexDirection='column' gap={40}>
           <Typography align='center' variant='displayLg' weight='bold' color={theme.palette.primary.navy}>IBAN Examples by country</Typography>
           <Box className={classes['rb-dropdown-container']}>
            <DropDown id='iban-dropdown' label='Country' placeholder='Select country' list={filteredCountryList.map((country,i) => renderCountry(country,i))} selectedItem={selectedCountry ? renderCountry(selectedCountry) : null} onSearch={handleCountrySearch}/>
        </Box>
        <Box>
            <Table columns={isMobile ? mobileColumnsData : columnsData} data={isMobile ? mobileRowData : rowData}  />
        </Box>
        </Box>
       </Box>
    </section>
  )
}

export default IBANExamples