import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { default as classNames, default as classnames } from 'classnames';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { setPromoRedirect } from '../../../../redux/customer/actions';
import CustomButton from '../../components/CustomButtonsWebsite/CustomButton';
import Hero from '../../components/Hero/Hero';
import HeroTitle from '../../components/Hero/HeroTitle';
import FeesCalculator from '../../components/FeesCalculator/FeesCalculator';
import dynamic from 'next/dynamic';
import { parseArrFromString } from '../../../../shared/utility';
import { setRedirectSignupCookie } from '../../../../shared/cookie-handler';
import MultiConverter from '../../components/DesignSystem/MultiConverterTab/MultiConverter';

const HorizontalExchangeConverter = dynamic(() => import('../../components/horizontalCurrencyConverter/HorizontalExchangeConverter'))
const MultiCurrencyConverter = dynamic(() => import('../../components/DesignSystem/MultiConverterTab/MultiConverter'));

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    textAlign: 'left',
    paddingTop: '120px',
    paddingBottom: '100px',
    color: '#FFFFFF',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      paddingTop: '80px',
      paddingBottom: '60px',
      clipPath: 'none',
    },
  },
  heroDiv: {
    height: '100%',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(6)
    }
  },
  bottomBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(5)
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3)
    }
  },
  imageTransformed: {
    maxWidth: '100% !important',
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(5)
    }
  },
  exchangeInputCard: {
    zIndex: '20',
    width: '100%',
    boxShadow: 0,
    border: '1px solid #CACCD5',
    [theme.breakpoints.down('sm')]: {
      padding: "24px 16px 16px",
    },
    [theme.breakpoints.up('sm')]: {
      padding: "40px 24px 32px",
    }
  },
  button: {
    marginTop: theme.spacing(4)
  },
  titleLogo: {
    width: '60px',
    height: '40px',
    [theme.breakpoints.down('sm')]: {
      width: '36px',
      height: '24px',
    }
  },
  main: {
    background: 'transparent',
    color: '#31517A !important'
  },
  image: {
    width: '100%',
  },
  leftSectionTitle: {
    fontWeight: 600,
    fontFamily: 'Inter',
  },
  leftSectionText: {
    fontWeight: 400,
    color: '#626680',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('xs')]: {
      lineHeight: '44px',
      fontSize: '24px',
    },
    [theme.breakpoints.down('md')]: {
      lineHeight: '36px',
      fontSize: '18px',
    },
  },
  imageFullWidth: {
    maxWidth: '100%',
  },
  logoutMobileStyles: {
    marginTop: '400px'
  },
  hideDiagonal: {
    "@media (max-width: 959px)": {
      display: 'none',
    },
  },
  container: {
    paddingRight: "0px",
    paddingLeft: "15px",
    marginRight: 'auto',
    marginLeft: 'auto',
    maxWidth: '1140px',
    '@media (min-width: 576px)': {
      maxWidth: "540px"
    },
    '@media (min-width: 768px)': {
      maxWidth: "720px"
    },
    '@media (min-width: 958px)': {
      maxWidth: "940px"
    },
    '@media (min-width: 992px)': {
      maxWidth: "960px"
    },
    '@media (min-width: 1200px)': {
      maxWidth: "1140px"
    }
  },
  overLay: {
    position: 'absolute',
    zIndex: '20',
    width: '100%',
    height: '100%'
  },
  overLapcontainer: {
    position: 'relative'
  },
  phone: {
    marginLeft: 'auto',
    '@media (max-width: 960px)': {
      marginLeft: 'auto',
      marginRight: 'auto',
      textAlign: 'center !important'
    },
    '& img': {
      float: 'right',
      "@media (max-width: 960px)": {
        float: 'none !important'
      },
    }
  },
  video: {
    maxWidth: '100%',
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  }
}))

const getRightSection = (type, backgroundImage, imageUrl, imageAlt, showImageOnMobile, videoUrl, videoRef, videoProps, containerRef, zoom, height, rightSectionProps, short, imageFullWidth, page, horizontalExchangeType, showSpotRate, moneyTransferProps) => {
  const classes = useStyles();
  const isNotMobile = useMediaQuery(theme => theme.breakpoints.up('md'));
  const showImage = !isNotMobile ? showImageOnMobile : true;
  const newVideoProps = { ...videoProps };
  delete newVideoProps.videoProps2;
  switch (type) {
    case 'MultiConverter': return <MultiCurrencyConverter />;
    case 'ExchangeInputBox': {
      const MTProps = moneyTransferProps ? parseArrFromString(moneyTransferProps) : [];
      const legacyMTProps = !moneyTransferProps && rightSectionProps?.length ? [
        {
          countryId: rightSectionProps[0].countryIdSelected,
          currency: rightSectionProps[0].currencydSelected
        }
      ] : [];
      return <MultiConverter componentType='moneyTransfer' moneyTransferProps={MTProps[0]} legacyMoneyTransferProps={legacyMTProps[0]} />
    };
    //case 'ExchangeInputBox': return <ExchangeInputBox unavailable={rightSectionProps && rightSectionProps.length && rightSectionProps[0].unavailable} currencySelected={rightSectionProps && rightSectionProps.length && rightSectionProps[0].currencySelected} countryIdSelected={rightSectionProps && rightSectionProps.length && rightSectionProps[0].countryIdSelected} defaultTransferValue={rightSectionProps && rightSectionProps.length && rightSectionProps[0].defaultTransferValue} altBox={short} styles={classes} showSpotRate={showSpotRate} />;
    case 'HorizontalExchangeConverter': return <HorizontalExchangeConverter originalCountryId={rightSectionProps && rightSectionProps[0].originalCountryId} convertedCountryId={rightSectionProps && rightSectionProps[0].convertedCountryId} type={horizontalExchangeType} vertical showSpotRate={showSpotRate} />;
    default: return (
      page !== 'logout' || (page === 'logout' && !isNotMobile) ?
        <>
          {imageUrl && showImage && <div className={classes.heroDiv}>
            <img
              src={imageUrl}
              alt={imageAlt}
              className={classNames(classes.image, !backgroundImage && classes.imageTransformed, imageFullWidth && classes.imageFullWidth)}
            />
          </div>}
          {videoUrl && <div className={classNames(classes.heroDiv, zoom ? classes.wrapper : null)} style={{ height: height }} ref={containerRef}>
            <video ref={videoRef} src={videoUrl} style={zoom ? { transform: `scale(${zoom})` } : null} controls={false} autoPlay='autoplay' {...newVideoProps} muted className={classes.video} />
          </div>}
        </>
        : null
    )
  }
}

const getBottomSection = (type, backgroundImage, imageUrl, imageAlt, bottomSectionProps, short, imageFullWidth, goToLink, horizontalExchangeType, showSpotRate) => {
  switch (type) {
    case 'HorizontalExchangeConverter': return <HorizontalExchangeConverter originalCountryId={bottomSectionProps && bottomSectionProps[0].originalCountryId} convertedCountryId={bottomSectionProps && bottomSectionProps[0].convertedCountryId} type={horizontalExchangeType} showSpotRate={showSpotRate} />;
    case 'FeesCalculator': return <FeesCalculator />;
    default: return null;
  }
}

const HeroHeader = ({
  title,
  text,
  logo,
  titleLogo,
  imageUrl,
  imageAlt,
  videoUrl,
  VideoProps,
  zoom,
  actionText,
  actionUrl,
  actionType,
  isVertical,
  rightSection,
  rightSectionType,
  rightSectionProps,
  bottomSection,
  bottomSectionType,
  bottomSectionProps,
  imageFullWidth,
  backgroundImage,
  page,
  short,
  promotion,
  promoRedirect,
  setPromoRedirect,
  horizontalExchangeType,
  horizontalExchangeRoute,
  showBottomArrow,
  hideTextOnMobile,
  subscribe,
  share,
  showActionMobile,
  showSpotRate,
  backgroundColor,
  showImageOnMobile,
  moneyTransferProps
}) => {
  let goToLink = null;
  if (horizontalExchangeRoute && horizontalExchangeType) {
    goToLink = () => {
      router.push({ pathname: horizontalExchangeRoute });
    }
  }
  const classes = useStyles();
  const router = useRouter()
  const [fullTitle, setFullTitle] = useState(title);
  const [fullText, setFullText] = useState(text);
  const [height, setHeight] = useState('100%');
  const [screenSize, setScreenSize] = useState(0);
  const [play, setPlay] = useState(false);
  const isNotMobile = useMediaQuery(theme => theme.breakpoints.up('md'));
  const videoRef = useRef();
  const containerRef = useRef();
  const subscribeSection = subscribe ? parseArrFromString(subscribe)[0] : null;
  const videoProps = VideoProps ? parseArrFromString(VideoProps)[0] : null;
  const logoItems = logo ? parseArrFromString(logo) : null;
  if (videoProps && videoProps.loop && typeof videoProps.loop === 'string') videoProps.loop = Boolean(videoProps.loop)

  if (rightSectionProps) {
    rightSectionProps = parseArrFromString(rightSectionProps);
  }
  if (bottomSectionProps) {
    bottomSectionProps = parseArrFromString(bottomSectionProps);
  }

  const handleResize = () => {
    setScreenSize(window.innerWidth)
  }

  if (videoRef && videoRef.current) {
    videoRef.current.addEventListener('play', () => {
      setPlay(true);
    });
  }

  if (typeof window !== 'undefined') {
    window.addEventListener("resize", handleResize)
  }

  useEffect(() => {
    if (zoom && videoRef && videoRef.current) {
      if (videoProps && videoProps.speed) videoRef.current.playbackRate = videoProps.speed;
      const value = containerRef.current.getBoundingClientRect().width * 65 / 96 * zoom;
      setHeight(`${value}px`);
    }
  }, [containerRef && containerRef.current && containerRef.current.getBoundingClientRect().width, screenSize, play])

  useEffect(() => {
    if (router && router.asPath && router.asPath.includes('currency-converter') && router.query && router.query.amount) {
      const urlParams = new URLSearchParams(window.location.search);
      const amount = urlParams.get('amount');
      const fromCode = router?.asPath?.substring(28, 31).toUpperCase();
      const toCode = router?.asPath?.substring(35, 38).toUpperCase();
      if (amount && fromCode && toCode) {
        const title1 = `${amount} ${fromCode.toUpperCase()} Canadian dollar to`;
        const title2 = `${toCode.toUpperCase()} Exchange Rate`;
        const newTitle = <span style={{ fontSize: '52px' }}>{title1}&nbsp;{title2}&nbsp;</span>
        const newText = `Convert ${amount} CAD to ${toCode} instantly with our Remitbee Currency Converter. Track historical exchange rates, see live CAD/${toCode} charts, and get free international money transfers for over 100 currencies`
        setFullTitle(newTitle);
        setFullText(newText);
      }
    } else {
      setFullTitle(title)
      setFullText(text);
    }
  }, [title])
  rightSection = getRightSection(rightSectionType, backgroundImage, imageUrl, imageAlt, showImageOnMobile, videoUrl, videoRef, videoProps, containerRef, zoom, height, rightSectionProps, short, imageFullWidth, page, horizontalExchangeType, showSpotRate, moneyTransferProps);
  bottomSection = getBottomSection(bottomSectionType, backgroundImage, imageUrl, imageAlt, bottomSectionProps, short, imageFullWidth, horizontalExchangeRoute && goToLink, horizontalExchangeType && horizontalExchangeType, showSpotRate);

  const goToUrl = async () => {
    let query = null;
    if (actionUrl && actionUrl.includes('signup')) {
      await setRedirectSignupCookie(router.asPath)
    }
    if (actionUrl && actionUrl.includes('signup?')) {
      router.push(actionUrl);
      return
    }
    if (promotion) {
      query = addReferralLink()
      if (promoRedirect) setPromoRedirect(promoRedirect);
    }
    router.push({ pathname: actionUrl, query });
  }

  const addReferralLink = () => {
    let _args = {}
    _args = { promo: promotion };
    return _args;
  }

  return (
    <div className={page === 'logout' ? classes.overLapcontainer : null} >
      {page === 'logout' && isNotMobile ?
        <div className={classes.overLay} >
          <section className={classnames(classes.root)}>
            <Grid
              container
              className={classes.container + ' ' + classes.alignContainer}
              alignItems="center"
            >
              <Grid item xs={12} md={6} className={classes.phone}>
                <img src={imageUrl} alt="logout image" />
              </Grid>
            </Grid>
          </section>
        </div>
        : null
      }
      <Hero
        className={!backgroundImage && classes.main}
        backgroundImage={backgroundImage || null}
        isVertical={isVertical}
        backgroundColor={backgroundColor}
        leftSection={
          <HeroTitle
            styles={classes}
            title={fullTitle}
            text={fullText}
            titleLogo={titleLogo}
            titleLogoClass={classes.titleLogo}
            logo={logoItems}
            hideTextOnMobile={hideTextOnMobile}
            className={!backgroundImage && classes.main}
            isActionTextHidden={showActionMobile ? !showActionMobile : rightSectionType === 'ExchangeInputBox'}
            subscribeSection={subscribeSection}
            shareSection={share}
          >
            {actionText &&
              <CustomButton
                action
                className={classes.button}
                type={actionType || "outlinedWhite"}
                onClick={goToUrl}
              >
                {actionText}
              </CustomButton>
            }
          </HeroTitle>
        }
        rightSection={!isNotMobile && !imageUrl && !videoUrl ? (
          <div className={classes.bottomBox}>
            {rightSection}
          </div>
        ) : rightSection}
        topSection={
          <HeroTitle
            bottom
            styles={classes}
            title={fullTitle}
            titleLogo={titleLogo}
            logo={logoItems}
            text={fullText}
            hideTextOnMobile={hideTextOnMobile}
            className={!backgroundImage && classes.main}
            isActionTextHidden={true}
          />
        }
        showBottomArrow={showBottomArrow}
        bottomSection={
          <div className={bottomSection ? classes.bottomBox : null}>
            {bottomSection}
          </div>
        }
        short={short}
        type={rightSectionType}
      />
      {page === 'logout' ?
        <div className={classnames(!isNotMobile ? classes.hideDiagonal : null)}>
          <svg width="100%" height="100px" viewBox="0 0 1280 140" preserveAspectRatio="none" xmlns="https://www.w3.org/2000/svg">
            <g fill="#105FF8">
              <path d="M1360 0 L0 80 L0 0" />
            </g>
          </svg>
        </div>
        : null
      }
    </div>
  )
}

export default connect(null, { setPromoRedirect })(HeroHeader);