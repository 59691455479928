import { Avatar, Box, IconButton, Typography, useMediaQuery } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { makeStyles } from '@material-ui/core/styles';
import Markdown from 'markdown-to-jsx';
import { Rating } from '@material-ui/lab';
import TabsSection from '../../components/Tabs/Tabs';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        marginTop: theme.spacing(5),
        overflowX: 'hidden',
        scrollBehavior: 'smooth'
    },
    insider: {
        width: 'auto',
        whiteSpace: 'nowrap',
        display: 'flex',
        columnGap: theme.spacing(2)
    },
    box: {
        width: '100%',
        display: 'inline-block',
        backgroundColor: '#F5F5F5',
        borderRadius: 16,
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(6)
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(4, 3)
        }
    },
    content: {
        [theme.breakpoints.up('sm')]: {
            width: '400px'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    buttons: {
        marginTop: theme.spacing(4),
        display: 'flex',
        width: '100%',
        justifyContent: 'left',
        columnGap: theme.spacing(2)
    },
    avatar: {
        width: 80,
        height: 80,
        marginRight: theme.spacing(3)
    },
    name: {
        fontWeight: 500,
        color: '#31517A',
        [theme.breakpoints.up('sm')]: {
            fontSize: '20px',
        },
        [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
        },
        lineHeight: '24px',
        fontFamily: 'Inter',
        textAlign: 'left',
        paddingTop: theme.spacing(1),
    },
    text: {
        textAlign: 'left',
        color: '#31517A',
        marginTop: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            fontSize: '18px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
        lineHeight: '26px',
        whiteSpace: 'pre-wrap'
    },
    button: {
        backgroundColor: '#EBF2FE'
    },
    quote: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    }
}));

const ReviewSlider = ({ list }) => {
    const classes = useStyles();
    const containerRef = useRef(null);
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const handleSlide = (direction) => {
        if (direction === 'right') containerRef.current.scrollLeft += 248;
        else containerRef.current.scrollLeft -= 248;
    }

    const getSingleCard = (item, index) => {
        const { photo, rating, text, name } = item;
        return (
            <div className={classes.box} key={index}>
                <div className={classes.content}>
                    <Box display='flex' alignItems='center'>
                        <Box>
                        {photo && <Avatar src={photo} className={classes.avatar} alt={`${name}'s photo`}/>}
                        </Box>
                        <Box flexGrow={1}>
                            <Box display='flex' justifyContent='left'>
                                {rating && <Rating value={parseInt(rating)} precision={0.1} readOnly size='small' />}
                            </Box>
                            {name && <Typography className={classes.name} component='div'>{name}</Typography>}
                        </Box>
                        <Box className={classes.quote}>
                            <img src="/new/user-quote.svg" alt="Quote" />
                        </Box>
                    </Box>
                    <Typography className={classes.text} component='div'>
                        <Markdown children={text} />
                    </Typography>
                </div>
            </div>
        )
    }

    return (
        <>
            {!isMobile && (
                <>
                <div className={classes.container} ref={containerRef}>
                    <div className={classes.insider}>
                        {list && list.length && list.map((item, index) => getSingleCard(item, index))}
                    </div>
                </div>
                {list && list.length && (
                    <Box className={classes.buttons}>
                        <IconButton color='primary' onClick={() => handleSlide('left')} className={classes.button}>
                            <ArrowBack />
                        </IconButton>
                        <IconButton color='primary' onClick={() => handleSlide('right')} className={classes.button}>
                            <ArrowForward />
                        </IconButton>
                    </Box>
                )}
                </>
            )}
            {isMobile && (
                <>
                    <TabsSection slider hideArrows data={list && list.length && list.map((item, index) => getSingleCard(item, index))} />
                </>
            )}
        </>
    )
}

ReviewSlider.defaultProps = {
    list: []
}

ReviewSlider.propTypes = {
    list: PropTypes.array
};

export default ReviewSlider;
