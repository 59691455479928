import React from 'react'
import classes from './FullWidthSection.module.scss'
import Markdown from 'markdown-to-jsx';
import Typography from '../../../../../remitbee/components/typography/Typography';
import theme from '../../../../../remitbee/theme/Theme';

const Table = ({ columns, data }) => {
    const columnKeys = Object.keys(columns[0]);

  return (
    <div className={classes["rb-table-container"]}>
    <table className={classes["rb-custom-table"]}>
    <thead>
          <tr>
            {columnKeys?.map((key, index) => (
              <th key={index}>
                {typeof columns[0][key] === 'string' ? <Typography variant='textXl' weight='semibold' color={theme.palette.primary.navy}>{<Markdown children={columns[0][key]} />}</Typography> : columns[0][key]}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columnKeys?.map((key, colIndex) => (
                <td key={colIndex}><Typography variant='textMd' weight='medium'>{typeof row[key] === 'string' ?  <Markdown children={row[key]} /> : row[key]}</Typography></td>
              ))}
            </tr>
          ))}
        </tbody>
    </table>
  </div>
  )
}

export default Table