import React, { useEffect, useRef, useState } from 'react';
import classes from './FullWidthSection.module.scss';
import Box from '../../../../../remitbee/components/box/Box';
import Typography from '../../../../../remitbee/components/typography/Typography';
import theme from '../../../../../remitbee/theme/Theme';
import { useMediaQuery } from '@material-ui/core';
import Markdown from 'markdown-to-jsx';

const CardList = ({ cardType, cardBackground, cardContent, cardBorder }) => {
  const [maxHeight, setMaxHeight] = useState(0);
  const isNotMobile = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);
  const boxShadowDefaultStyle = '0px 1px 2px 0px rgba(16, 24, 40, 0.05)';
  const cardRefs = useRef<any>([]);

  useEffect(() => {
    const heights = cardRefs?.current?.map((card) => card?.getBoundingClientRect()?.height);
    const maxCardHeight = Math.max(...heights);
    setMaxHeight(maxCardHeight);
  }, [cardRefs?.current?.length]);

  return (
    <Box className={classes['rb-cardList-container']} mt={10}>
      {cardContent.map((e, i) => (
        <Box
          key={i}
          componentRef={(el) => (cardRefs.current[i] = el)}
          style={{ background: cardBackground ? cardBackground : '#FFF', minHeight: isNotMobile && maxHeight, border: cardBorder, boxShadow: cardBorder ? 'none' : boxShadowDefaultStyle, width: isNotMobile && e.cardWidth}}
          className={classes['rb-card-container']}
        >
          {(cardType === 'icon' || cardType === 'number') && (
            <Box>
              {cardType === 'icon' && (
                <div className={`${classes['rb-icon-container']} ${e.imageAlign && classes[`rb-icon-align-${e.imageAlign}`]}`}>
                  <img
                    src={e.icon}
                    alt="image"
                    width={e.iconWidth || 32}
                    height={e.iconHeight || 32}
                  />
                </div>
              )}
              {cardType === 'number' && (
                <div className={classes['rb-numbered-icon']}>
                  <Typography variant="textXl" weight="bold" color="#FFF">
                    {i + 1}
                  </Typography>
                </div>
              )}
            </Box>
          )}
          <Box
            display="flex"
            flexDirection="column"
            gap={8}
            mt={cardType === 'icon' || cardType === 'number' ? 8 : 0}
          >
            <Typography variant="textXl" align={e.titleAlign ? e.titleAlign : 'left'} weight="bold" color={theme.palette.primary.navy}>
            <Markdown
              children={e.title}
            />
            </Typography>
           {e.text && <Typography paragraph={true} variant="textMd" align={e.textAlign ? e.textAlign : 'left'} weight="regular">
            <Markdown
              children={e.text}
            />
            </Typography>}
            {e.timeText &&<Typography paragraph={true} variant="textMd" align={e.textAlign ? e.textAlign : 'left'} weight="regular">
            <Markdown
              children={e.timeText}
            />
            </Typography>}

            {e.hoursText && <Typography paragraph={true} variant="textMd" align={e.textAlign ? e.textAlign : 'left'} weight="regular">
            <Markdown
              children={e.hoursText}
            />
            </Typography>}
            {e.locationText &&<Typography paragraph={true} variant="textMd" align={e.textAlign ? e.textAlign : 'left'} weight="regular">
            <Markdown
              children={e.locationText}
            />
            </Typography>}
          </Box>
          {cardType === 'specialTag' && (
            <div className={classes['rb-className-specialTag']}>
              <Typography variant="textSm" weight="medium" color={theme.palette.primary.blue}>
                {e.tag}
              </Typography>
            </div>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default CardList;
