import { ListItemIcon, ListItemText } from '@material-ui/core';
import { ListItem } from '@material-ui/core';
import { List } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useTheme, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import classnames from 'classnames';
import Markdown from 'markdown-to-jsx';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React from 'react';
import { parseArrFromString } from '../../../../shared/utility';
import FeesTable from '../../components/FeesTable/FeesTable';
import DebitCalculator from '../../components/FeesCalculator/DebitCalculator';
import Tabs from '../../components/Tabs/Tabs';
import { container, whiteColor } from '../../globalStyles';
import { setRedirectSignupCookie } from '../../../../shared/cookie-handler';

const ExchangeInputBox = dynamic(() => import('../../components/ExchangeInputBox/ExchangeInputBox'))
const CustomButton = dynamic(() => import('../../components/CustomButtonsWebsite/CustomButton'))
const HorizontalExchangeConverter = dynamic(() => import('../../components/horizontalCurrencyConverter/HorizontalExchangeConverter'))
const SavingsCalculator = dynamic(() => import('../../components/FeesCalculator/SavingsCalculator'))
const ComparisonTable = dynamic(() => import('../../components/ComparisonTable/ComparisonTable'))

const styles = (theme) => ({
  container,
  body1: {
    color: '#626680',
    lineHeight: '34px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    }
  },
  root: {
    display: 'flex',
    width: '100%',
    textAlign: 'left',
    backgroundColor: 'transparent',
    color: '#FFFFFF',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      clipPath: 'none',
    },
  },
  imageLogoutWorld: {
    maxWidth: '100%',
    marginLeft: '-90px'
  },
  textAligner: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
  textInfo: {
    fontFamily: 'Inter',
    //marginBottom: theme.spacing(2),
    fontWeight: 600,
    [theme.breakpoints.up('sm')]: {
      fontSize: '36px',
      lineHeight: '48px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '26px',
      lineHeight: '40px'
    },
  },
  textInfoMobile: {
    fontFamily: 'Inter',
    //marginBottom: theme.spacing(2),
    fontWeight: 600,
    [theme.breakpoints.up('sm')]: {
      fontSize: '36px',
      lineHeight: '48px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '26px',
      textAlign: 'center',
      lineHeight: '40px'
    },
  },
  textInfoNoMargin: {
    marginBottom: '0px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0px',
    },
  },
  mobileActionButton: {
    display: 'none',
    '@media (max-width: 500px)': {
      display: 'flex',
      width: '50%',
      marginLeft: 'auto',
      marginRight: 'auto',
      fontSize: '18px'
    },
  },
  image: {
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '350px',
    },
  },
  actionButton: {
    marginTop: theme.spacing(4),
  },
  alignContainer: {
    alignItems: 'center',
  },
  colored: {
    backgroundColor: whiteColor,
  },
  subtitle: {
    color: '#626680',
    fontWeight: 400,
    margin: theme.spacing(2, 0),
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px',
      lineHeight: '44px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
      lineHeight: '38px',
    }
  },
  tabs: {
    marginTop: theme.spacing(2)
  },
  list: {
    padding: 0,
    marginTop: theme.spacing(2)
  },
  noPadding: {
    padding: 0,
  },
  listIcon: {
    minWidth: '36px'
  },
  listText: {
    fontFamily: 'Inter',
    fontWeight: 400,
    lineHeight: '27px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    }
  },
  listItem: {
    '&:not(:last-child)': {
      paddingBottom: theme.spacing(2),
    },
    paddingTop: 0,
    color: '#626680',
    lineHeight: '27px',
  },
  listItemText: {
    margin: 0
  },
  color: {
    color: "#FFFFFF"
  },
  mobileTag: {
    fontFamily: 'Inter',
    paddingBottom: theme.spacing(1),
    fontWeight: 700,
    color: '#1160F7',
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
      lineHeight: '30px',
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '16px',
      lineHeight: '23.5px',
    }
  },
  tag: {
    fontFamily: 'Inter',
    paddingBottom: theme.spacing(1),
    fontWeight: 700,
    color: '#1160F7',
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
      lineHeight: '30px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '23.5px',
    }
  },
  alignStart: {
    alignItems: 'flex-start'
  },
  leftBox: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(5),
    },
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(6)
    }
  },
  rightBox: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(5),
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(6)
    }
  }
});

const HalfColumnSection = (props) => {
  const {
    classes,
    title,
    text,
    contentType,
    reverse,
    colored,
    actionText,
    actionUrl,
    tag,
    list,
    actionType,
    TextProps,
    TitleProps,
    TableProps,
    TableData,
    ButtonProps,
    subtitle,
    countryIdSelected,
    backgroundImage,
    color,
    bankInfo,
    element,
    promotion,
    tabs,
    rows
  } = props;
  const content = parseArrFromString(list);
  const tabsContent = tabs ? parseArrFromString(tabs) : null;
  const tableProps = TableProps ? parseArrFromString(TableProps)[0] : null;
  const tableData = TableData ? parseArrFromString(TableData) : null;
  const tableContent = rows ? parseArrFromString(rows) : null;
  const rootStyle = {};
  const theme = useTheme();
  const router = useRouter();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const colorStyles = color ? { color } : null;

  if (backgroundImage) {
    rootStyle.background = `url(${backgroundImage}) no-repeat center`;
    rootStyle.backgroundSize = 'cover';
  }
  if (isMobile) {
    if (backgroundImage) {
      rootStyle.background = 'transparent';
    }
  }
  rootStyle.backgroundColor = colored ? colored : 'transparent';

  const goToUrl = async () => {
    let query = null;
    if (actionUrl && actionUrl.includes('signup')) {
      await setRedirectSignupCookie(router.asPath)
    }
    if (promotion) {
      query = addReferralLink()
    }
    router.push({ pathname: actionUrl, query });
  }

  const addReferralLink = () => {
    let _args = {}
    _args = { promo: promotion };
    return _args;
  }

  const renderList = () => {
    if (content) return (
      <List dense className={classes.list}>
        {content.map(item => (
          <ListItem disableGutters className={classes.listItem}>
            <ListItemIcon className={classes.listIcon}>
              <img src="/new/list-icon.svg" width="21px" height="21px" />
            </ListItemIcon>
            <ListItemText className={classes.listItemText}>
              <Typography component='div' className={classes.listText}>{item.text}</Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    )
  }

  const getContent = (type) => {
    switch (type) {
      case 'ExchangeInputBox': return (<ExchangeInputBox countryIdSelected={countryIdSelected} side={reverse ? 'left' : 'right'} autoFocus={false} />)
      case 'CurrencyBox': return (<HorizontalExchangeConverter vertical />)
      case 'SavingsCalculator': return (<SavingsCalculator />)
      case 'DebitCalculator': return (<DebitCalculator />)
      case 'FeesTable': return (<FeesTable {...tableProps} rows={tableContent} />)
      case 'ComparisonTable': return (<ComparisonTable {...tableProps} tableData={tableData} />)
    }
  }

  return (
    <section
      className={classes.root}
      style={rootStyle}
    >
      <Grid
        container
        className={classnames(classes.container, classes.noPadding, (bankInfo) ? classes.alignStart : classes.alignContainer)}
        direction={reverse ? 'row-reverse' : 'row'}
        alignItems="center"
      >
        <Grid item xs={12} md={6} className={classes.textArea}>
          {tag && (
            <Typography variant='body1' component="div" className={classes.tag}>{tag}</Typography>
          )}
          <Typography
            variant="h2"
            component="div"
            marked="center"
            className={classes.textInfo}
            style={colorStyles}
            {...TitleProps}
          >
            {title}
          </Typography>
          {subtitle && (
            <Typography component="div" variant="subtitle1" style={colorStyles} className={classes.subtitle}>
              {subtitle}
            </Typography>
          )}
          {text && <div className={classnames(classes.body1, classes.textAligner)} style={colorStyles} {...TextProps}>
            {typeof text == 'string' ? <Markdown children={text} /> : text}
          </div>}
          {tabsContent && <Tabs data={tabsContent} />}
          {list && renderList()}
          {actionText && element !== 'first' ?
            <CustomButton
              action
              className={classes.actionButton}
              type={actionType || 'outlinedBlue'}
              onClick={goToUrl}
              {...ButtonProps}
            >
              {actionText}
            </CustomButton>
            : null}
        </Grid>
        {contentType && (
          <Grid item xs={12} md={6} className={reverse ? classes.leftBox : classes.rightBox}>
            {getContent(contentType)}
          </Grid>
        )}
        {actionText && <Grid item xs={12} className={classes.textArea}>
          {element === 'first' ?
            <CustomButton
              action
              className={classes.mobileActionButton}
              type={actionType || 'outlinedBlue'}
              onClick={goToUrl}
              {...ButtonProps}
            >
              {actionText}
            </CustomButton>
            : null}
        </Grid>}
      </Grid>
    </section>
  );
}

HalfColumnSection.propTypes = {
  classes: PropTypes.object.isRequired,
  TextProps: PropTypes.object,
  TitleProps: PropTypes.object,
  ButtonProps: PropTypes.object,
  actionText: PropTypes.string,
  actionUrl: PropTypes.string,
  actionType: PropTypes.string,
  title: PropTypes.string,
  list: PropTypes.object,
  bankInfo: PropTypes.bool
};

export default withStyles(styles)(HalfColumnSection);