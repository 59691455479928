import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    leftGrid: {
        [theme.breakpoints.up('md')]: {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            paddingRight: theme.spacing(4)
        },
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(5)
        }
    },
    grid: {
        background: '#1160F7',
        minHeight: '100px',
        [theme.breakpoints.up('sm')]: {
            borderLeft: '1px solid #ffffff',
            padding: theme.spacing(8, 2),
        },
        [theme.breakpoints.down('sm')]: {
            borderTop: '1px solid #ffffff',
            padding: theme.spacing(5, 2),
        }
    },
    title: {
        width: '100%',
        fontFamily: 'Inter',
        fontWeight: 500,
        color: '#31517A',
        textAlign: 'left',
        [theme.breakpoints.up('sm')]: {
            fontSize: '32px',
            lineHeight: '48px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '26px',
            lineHeight: '39px',
        }
    },
    subtitle: {
        width: '100%',
        paddingTop: theme.spacing(2),
        fontFamily: 'Inter',
        fontWeight: 400,
        color: '#626680',
        textAlign: 'left',
        [theme.breakpoints.up('xs')]: {
            fontSize: '24px',
            lineHeight: '36px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '16px',
            lineHeight: '24px',
        }
    },
    rating: {
        paddingTop: theme.spacing(2),
        fontFamily: 'Inter',
        fontWeight: 500,
        color: '#ffffff',
        textAlign: 'center',
        fontSize: '24px',
        lineHeight: '30px'
    },
    logo: {
        minHeight: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: theme.spacing(8)
    },
    image: {
        width: '100%',
        maxWidth: 170
    }
}));

const ReviewBoxes = ({ title, text }) => {
    const classes = useStyles();
    return (
        <Grid container>
            <Grid item md={4} xs={12} className={classes.leftGrid}>
                <div>
                    <Typography className={classes.title}>{title}</Typography>
                    <Typography className={classes.subtitle}>{text}</Typography>
                </div>
            </Grid>
            <Grid item container md={8} xs={12}>
                <Grid item xs={12} sm={4} className={classes.grid}>
                    <img src='/new/rating-stars.svg' alt='Rating' className={classes.image} />
                    <Typography className={classes.rating}>4.7 Star Rating</Typography>
                    <div className={classes.logo}>
                        <img src='/new/google-white.svg' alt='Rating' className={classes.image} />
                    </div>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.grid}>
                    <img src='/new/rating-stars.svg' alt='Rating' className={classes.image} />
                    <Typography className={classes.rating}>4.4 Star Rating</Typography>
                    <div className={classes.logo}>
                        <img src='/new/trustpilot-white.svg' alt='Rating' className={classes.image} />
                    </div>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.grid}>
                    <img src='/new/rating-stars.svg' alt='Rating' className={classes.image} />
                    <Typography className={classes.rating}>4.5 Star Rating</Typography>
                    <div className={classes.logo}>
                        <img src='/new/facebook-white.svg' alt='Rating' className={classes.image} />
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
};

ReviewBoxes.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string
}

export default ReviewBoxes;
