import React, { useEffect, useState } from 'react';
import classes from './FullWidthSection.module.scss';
import { useMediaQuery } from '@material-ui/core';
import theme from '../../../../../remitbee/theme/Theme';
import { useRouter } from 'next/router';
import { getImageAlt, parseArrFromString } from '../../../../../shared/utility';
import Box from '../../../../../remitbee/components/box/Box';
import { setRedirectSignupCookie } from '../../../../../shared/cookie-handler';
import Button from '../../../../../remitbee/components/button/Button';
import Placeholder from '../../../../../remitbee/icons/PlaceholderIcon';
import Spacing from '../../../../../remitbee/styles/spacing/Spacing';
import Typography from '../../../../../remitbee/components/typography/Typography';
import ArrowDown from '../../../../../remitbee/icons/ArrowDown';
import ArrowUp from '../../../../../remitbee/icons/ArrowUp';
import Markdown from 'markdown-to-jsx';
import CardList from './CardList';
import FeesTable from '../../../components/FeesTable/FeesTable';
import Table from './Table';
import TransferLimitsCard from './TransferLimitsCard';
import SimpleConversionTable from './SimpleConversionTable';
import TopCurrencyPairs from './TopCurrencyPairs';
import IBANExample from './IBANExample';

interface Props {
    title?: string | React.ReactNode;
    titleVariant?: string;
    titleWeight?: string;
    titleColor?: string;
    tag?: string;
    tagVariant?: string;
    tagWeight?: string;
    tagColor?: string;
    text?: string | React.ReactNode;
    textVariant?: string;
    textWeight?: string;
    textColor?: string;
    textAlignment?: string;
    primaryButtonText?: string;
    primaryButtonAction?: string;
    primaryButtonVariant?: string;
    primaryButtonStartIconVariant?: string | boolean;
    primaryButtonEndIconVariant?: string;
    secondaryButtonText?: string;
    secondaryButtonAction?: string;
    secondaryButtonVariant?: string;
    secondaryButtonStartIconVariant?: string;
    secondaryButtonEndIconVariant?: string;
    imageList?: string;
    imageUrl?: string;
    mobileImageUrl?: string;
    faqList?: string | any[];
    backgroundColor?: string;
    border?: string;
    promotion?: any;
    titleAlignment?: string;
    tagAlignment?: string;
    imageOpacity?: string
    bottomList?: any;
    bottomListItemsGrid?: number | string;
    cardList?: any;
    cardType?: any;
    cardBorder?: any;
    cardBackground?: string;
    tableColumns?: any;
    tableData?: any;
    showTransferLimits?: boolean;
    contentType?: string;
    currency_code?: string;
    currencies?: any;
    rootClass?: string;
}

const FullWidthSection:React.FC<Props> = ({
    title,
    titleAlignment,
    titleVariant,
    titleWeight,
    titleColor, 
    tag,
    tagAlignment,
    tagVariant,
    tagWeight,
    tagColor,
    text,
    textVariant,
    textWeight,
    textColor,
    textAlignment,
    primaryButtonText,
    primaryButtonAction,
    primaryButtonVariant,
    primaryButtonStartIconVariant,
    primaryButtonEndIconVariant,
    secondaryButtonText,
    secondaryButtonAction,
    secondaryButtonVariant,
    secondaryButtonStartIconVariant,
    secondaryButtonEndIconVariant,
    imageList,
    imageUrl,
    mobileImageUrl,
    faqList,
    backgroundColor,
    border,
    promotion,
    imageOpacity,
    bottomList,
    bottomListItemsGrid,
    cardList,
    cardType,
    cardBorder,
    cardBackground,
    tableColumns,
    tableData,
    showTransferLimits,
    contentType,
    currency_code,
    currencies,
    rootClass = '',
}) => {
    const isNotTablet = useMediaQuery(`(min-width: ${theme.breakpoints.lg}px)`);
    const isNotMobile = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);
    const [isMediaQueryReady, setIsMediaQueryReady] = useState(false);

  useEffect(() => {
    setIsMediaQueryReady(true);
  }, [isNotMobile]);

    const imagesList = imageList ? typeof imageList==='string' ? parseArrFromString(imageList) : imageList : null;
    const faq = faqList ? typeof faqList==='string' ? parseArrFromString(faqList) : faqList : null;
    const mainList = bottomList ? typeof bottomList==='string' ? parseArrFromString(bottomList) : bottomList : null;
    const cardListItems = cardList ? typeof cardList === 'string' ? parseArrFromString(cardList) : cardList : null;
    const tableDataConverted = tableData ? typeof tableData === 'string' ? parseArrFromString(tableData) : tableData : null;
    const tableColumnsConverted = tableColumns ? typeof tableColumns === 'string' ? parseArrFromString(tableColumns) : tableColumns : null;
    const currencyPairsList = currencies ? typeof currencies === 'string' ? parseArrFromString(currencies) : currencies : null;
    const showContentFirst = cardListItems || tableColumnsConverted || showTransferLimits;
    const rootStyle = rootClass ? JSON.parse(rootClass) : null;

    const mainListGrid = bottomListItemsGrid || mainList?.length

    const [openFaq, setOpenFaq] = React.useState(null);

    const router = useRouter();

    const addReferralLink = () => {
        let _args = {}
        _args = { promo: promotion };
        return _args;
    }

    const goToUrl = async (actionUrl) => {
        if (!actionUrl) return;
        if (actionUrl.startsWith('http')) {
            window.open(actionUrl, '_blank');
        } else if (actionUrl.startsWith('www')) {
            window.open(`https://${actionUrl}`, '_blank');
        } else {
            let query = null;
            if (actionUrl && actionUrl.includes('signup')) await setRedirectSignupCookie(router.asPath)
            if (actionUrl && actionUrl.includes('signup?')) {
                router.push(actionUrl);
                return
            }
            if (promotion) query = addReferralLink()
            router.push({ pathname: actionUrl, query });
        }
    };

    const primaryButton = (primaryButtonText && <Button
        fullWidth={!isNotMobile}
        id={'columnImagePrimaryBtnId'}
        className={classes['rb-button-primary']}
        variant={primaryButtonVariant ? primaryButtonVariant : 'filled'}
        onClick={() => goToUrl(primaryButtonAction)}
        startIcon={primaryButtonStartIconVariant ? <Placeholder color={primaryButtonStartIconVariant === 'string' ? primaryButtonStartIconVariant : theme.palette.background.white}/> : null}
        endIcon={primaryButtonEndIconVariant ? <Placeholder color={primaryButtonEndIconVariant === 'string' ? primaryButtonEndIconVariant : theme.palette.background.white}/> : null}
        >
        {primaryButtonText}
    </Button>)

    const secondaryButton = secondaryButtonText && (<Button
        fullWidth={!isNotMobile}
        id={'columnImageSecondaryBtnId'}
        className={classes['rb-button-secondary']}
        variant={secondaryButtonVariant ? secondaryButtonVariant : 'outlined'}
        onClick={() => goToUrl(secondaryButtonAction)}
        startIcon={secondaryButtonStartIconVariant ? <Placeholder color={secondaryButtonStartIconVariant === 'string' ? secondaryButtonStartIconVariant : theme.palette.primary.blue}/> : null}
        endIcon={secondaryButtonEndIconVariant ? <Placeholder color={secondaryButtonEndIconVariant === 'string' ? secondaryButtonEndIconVariant : theme.palette.primary.blue}/> : null}
        >
        {secondaryButtonText}
    </Button>)

    const getComponents = () => {
        switch (contentType) {
            case 'SimpleConversionTable':
                return <SimpleConversionTable currency_code={currency_code} />
            case 'TopCurrencyPairs':
                return <TopCurrencyPairs currencyPairsList={currencyPairsList} />    
        }
    }

    const handleClick = (link) => window.open(link)

    return (
        <section style={{ backgroundColor: backgroundColor || null, border: border || null }}>
            <Box
                flexDirection={'column'}
                className={classes['rb-root']}
                style={rootStyle}
            >

                <Box display='flex' flexDirection={'column'}>
                    {(tag || title) && <Box display='flex' flexDirection={'column'} gap={8}>
                        {tag && <Typography
                            className={classes['rb-tag']}
                            variant={tagVariant || 'textMd'}
                            weight={tagWeight || 'bold'}
                            color={tagColor || theme.palette.primary.blue}
                            align={tagAlignment || 'center'}
                        >
                            {tag}
                        </Typography>}
                        {title && <Typography
                            className={classes['rb-title']}
                            variant={(titleVariant || 'displayMd')}
                            weight={titleWeight || 'bold'}
                            color={titleColor || theme.palette.primary.navy}
                            align={titleAlignment || 'center'}
                        >
                            {title}
                        </Typography>}
                    </Box>}
                    {(tag || title) && <Spacing variant='betweenHeaderToSubheader' />}
                    {text && <Typography
                        paragraph={true}
                        className={classes['rb-text']}
                        variant={textVariant || 'textMd'}
                        weight={textWeight || 'regular'}
                        color={textColor || theme.palette.input.body}
                        align={textAlignment || 'center'}
                    >
                        {typeof text === 'string' ? <Markdown children={text} /> : text}
                    </Typography>}
                </Box>
                
                {showTransferLimits && <Box mt={16} mb={2}><TransferLimitsCard /></Box>}
                {contentType && getComponents()}

                {(primaryButton || secondaryButton) && !showContentFirst && <Box>
                    <Spacing variant='betweenSections' />
                    <Box display='flex' justifyContent='center' gap={16}>
                        {primaryButton}
                        {secondaryButton}
                    </Box>
                </Box>}
                <Box>
                    {(imagesList || mainList || faq || imageUrl) && <Spacing variant='titleToCard' />}
                    {imagesList && <Box display='flex' justifyContent='center' gap={32} flexWrap='wrap'>
                        {imagesList.map((item, index) => {
                            return (
                                <Box key={index} onClick={(item?.onClick) ? () => handleClick(item.onClick) : null} className={classes['rb-imagesList-box']} style={{ cursor: item?.onClick ? 'pointer' : ''}}>
                                    <img src={item?.src} alt={item.alt || getImageAlt(item.src) || 'imageAlt'} className={classes['rb-imagesList']} style={{ opacity: imageOpacity}}/>
                                </Box>
                            )
                        })}
                    </Box>}

                    {(imageUrl || mobileImageUrl) && isMediaQueryReady && <img src={!isNotMobile && mobileImageUrl ? mobileImageUrl : imageUrl} alt={getImageAlt(imageUrl)} className={classes['rb-image']} />}

                    {mainList && <Box
                        display='flex'
                        flexDirection={!isNotMobile ? 'column' : 'row'}
                        justifyContent={'space-between'}
                        alignItems={'flex-start'}
                        flexWrap={'wrap'}
                        mt={6}
                    >
                        {mainList?.map((item, index) =>
                            <Box
                                display='flex'
                                key={index}
                                justifyContent='center'
                                alignItems='flex-start'
                                gap={24}
                                className={`
                                ${classes['rb-mainListItems-root']}
                            `}
                                style={{ width: !isNotMobile ? '100%' : `calc(100% / ${mainListGrid})` }}
                            >
                                <Box
                                    display='flex'
                                    flexDirection='column'
                                    className={classes['rb-mainListItems-icon']}
                                >
                                    {item.icon ? <img src={item.icon} width={!isNotTablet ? 32 : 36} height={!isNotTablet ? 32 : 36} /> : <Placeholder color={theme.palette.primary.blue} width={!isNotTablet ? 32 : 36} height={!isNotTablet ? 32 : 36} />}
                                </Box>
                                <Box display='flex' flexDirection='column' gap={4}>
                                    <Typography variant='textXl' weight='semibold' color={theme.palette.primary.navy} align='left'>
                                        <Markdown children={item.title} />
                                    </Typography>
                                    <Typography paragraph={true} variant='textMd' align='left' className={classes['rb-mainListItems-text']}>
                                        <Markdown children={item.text} />
                                    </Typography>
                                </Box>
                            </Box>)}
                    </Box>}
                    
                    {faq && <Box className={classes['rb-faq-box']}>
                        {faq.map((item, index) => {
                            return (
                                <Box className={classes['rb-faq-item-box']} onClick={()=>setOpenFaq(openFaq !== index ? index : null)}>
                                    <Box key={index} className={classes['rb-faq-question-box']}>
                                        <Typography variant='textXl' weight='medium' color={theme.palette.primary.navy} className={classes['rb-faq-question']}>
                                            {item.question}
                                        </Typography>
                                        {openFaq === index ? <ArrowUp width={24} height={24} color={theme.palette.primary.blue} />
                                        : <ArrowDown width={24} height={24} color={theme.palette.primary.blue} />}
                                    </Box>
                                    {openFaq === index && <Box className={classes['rb-faq-answers-box']}>
                                        <Typography variant='textMd' className={classes['rb-faq-answer']}>
                                            {item.answers}
                                        </Typography>
                                    </Box>}
                                </Box>
                            )
                        })}
                    </Box>}

                    {cardListItems && cardListItems?.length && <CardList cardContent={cardListItems} cardType={cardType} cardBackground={cardBackground} cardBorder={cardBorder} />}
                    {tableColumnsConverted && <Box mt={10}><Table columns={tableColumnsConverted} data={tableDataConverted} /></Box>}

                    {contentType === 'IBANExample' ? <IBANExample /> : null}
                    {(primaryButton || secondaryButton) && showContentFirst && <Box>
                        <Spacing variant='betweenSections' />
                        <Box display='flex' justifyContent='center' gap={16}>
                            {primaryButton}
                            {secondaryButton}
                        </Box>
                    </Box>}
                </Box>

            </Box>
        </section>
    )
}

export default FullWidthSection;
